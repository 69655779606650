import { useNavigate } from "react-router-dom";
import "../styles/tic-tac-toe-game.css";
import Game from "./Game";

function TicTacToeGame({ setShowNav }) {
  let navigate = useNavigate();

  return (
    <div>
      <div className="tic-tac-toe-game-container">
        <Game />
        <button
          className="back-button"
          onClick={() => (navigate(-1), setShowNav(true))}
        >
          Back.
        </button>
      </div>
    </div>
  );
}

export default TicTacToeGame;
