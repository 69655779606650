import { useState } from "react";
import JavaScriptLogo from "./images/JavaScript-logo.png";
import HTMLLogo from "./images/HTML-logo2.jpeg";
import CSSLogo from "./images/CSS-logo.jpg";
import ReactLogo from "./images/React-logo.svg";
import TypeScriptLogo from "./images/TS-logo.png";
import NodejsLogo from "./images/nodejs-logo.png";
import "../styles/matchingApp.css";
import SingleCard from "./SingleCard";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const cardImages = [
  { src: JavaScriptLogo, matched: false },
  { src: HTMLLogo, matched: false },
  { src: CSSLogo, matched: false },
  { src: ReactLogo, matched: false },
  { src: TypeScriptLogo, matched: false },
  { src: NodejsLogo, matched: false },
];

function App({ setShowNav }) {
  let navigate = useNavigate();

  const [cards, setCards] = useState([]);
  const [turns, setTurns] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const shuffleCards = () => {
    const shuffledCards = [...cardImages, ...cardImages]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));
    setChoiceOne(null);
    setChoiceTwo(null);
    setCards(shuffledCards);
    setTurns(0);
  };

  const handleChoice = (card) => {
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
  };

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (choiceOne.src === choiceTwo.src) {
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.src === choiceOne.src) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
        resetTurn();
      } else {
        setTimeout(() => resetTurn(), 800);
      }
    }
  }, [choiceOne, choiceTwo]);

  useEffect(() => {
    shuffleCards();
  }, []);

  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns((prevTurns) => prevTurns + 1);
    setDisabled(false);
  };

  return (
    <div className="main-matching-app-container">
      <div className="matching-app">
        <h1>Magic Match</h1>
        <button onClick={shuffleCards}>New Game</button>
        <div className="card-grid">
          {cards.map((card) => (
            <SingleCard
              key={card.id}
              card={card}
              handleChoice={handleChoice}
              disabled={disabled}
              flipped={card === choiceOne || card === choiceTwo || card.matched}
            />
          ))}
        </div>
        <p>Turns: {turns}</p>
        <button
          className="back-button"
          onClick={() => (navigate(-1), setShowNav(true))}
        >
          Back.
        </button>
      </div>
    </div>
  );
}

export default App;
