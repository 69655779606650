import React from "react";
import { useRef } from "react";
import "../styling/emailModal.scss";
import emailjs from "@emailjs/browser";

export const EmailModal = ({
  open,
  onClose,
  emailSuccess,
  setEmailSuccess,
}) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_s6x174k",
        "template_0w33j6r",
        form.current,
        "d-fmGuQ8qPU_hByOm"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setEmailSuccess(true);
  };
  if (!open) return null;
  return (
    <div onClick={onClose} className="modal-overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modal-container"
      >
        <div className="close-modal-button-container">
          <h3 onClick={onClose} className="close-modal-button">
            X
          </h3>
        </div>
        <div className="modal-text-container">
          <h2>Fill out this form to send me an Email.</h2>
          <p>
            Please include a detailed message about what you want to discuss.
            Feel free to incude your LinkedIn Profile or your phone number if
            you would like.
          </p>
        </div>
        <form ref={form} onSubmit={sendEmail} action="#">
          <div className="form-group">
            <input
              placeholder="Email Address"
              type="email"
              name="user_email"
              required
              className="form-field"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="user_name"
              required
              placeholder="Full Name"
              className="form-field"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="user_subject"
              required
              placeholder="Subject Line"
              className="form-field"
            />
          </div>
          <div className="form-group">
            <textarea
              placeholder="Message"
              name="user_message"
              rows="6"
              required
              className="form-field"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Submit Form
            </button>

            {emailSuccess ? (
              <div className="message">
                <div className="successful-message">
                  Your Email was successfully sent to Arthur!
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
