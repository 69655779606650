import React from "react";
import "../styling/lightSaber.scss";

export const LightSabers = () => {
  return (
    <div className="main-light-saber-container">
      <div id="lightsaber1" class="example-item">
        <div class="lightsaber">
          <label for="yoda-example"></label>
          <input type="checkbox" id="yoda-example" checked="checked" />
          <div class="switch"></div>
          <div class="plasma yoda"></div>
        </div>
      </div>
    </div>
  );
};
