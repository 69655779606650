export const projects = [
  {
    title: "RideFile",
    description:
      "One interesting project that I have worked on using React.js is an app that I call RideFile. It was created to allow gear heads to create profiles for their vehicles, and these profiles were secured using JSON Web Token. With this app, users can create profiles for their vehicles that include information about the make, model, year, and modifications. I also included a theme switcher that would switch the whole app into an 80s mode.",
    image: "rideFileScreenShot",
    techUsed: [
      "HTML",
      "SCSS",
      "MongoDB",
      "JWT",
      "React.js",
      "FireBase",
      "JavaScript",
    ],
    id: 0,
  },
  {
    title: "Midi-Modo",
    description:
      "This project was a React.js web app for midi modo, an NFT Project 'built for Musicians by Musicians'. This app was created using React.js for the frontend and SCSS for the styling. By using React.js and SCSS together, we were able to create a fast, responsive, and visually appealing app. It was a fun and challenging project that allowed us to showcase the power of React.js and SCSS in building engaging frontend applications. ",
    image: "midiModoScreenShot",
    techUsed: ["HTML", "SCSS", "React.js", "TypeScript", "JavaScript"],
    id: 1,
  },
  {
    title: "Imagynate",
    description:
      "This project is my favorite mobile app that I have worked on using React Native. We used React Native to create a modern and visually appealing user interface that makes it easy for photographers to quickly find and manage their photos. The app was created using React Native, which allows for the development of native mobile apps using JavaScript and React. The app includes a variety of features such as album creation / deletion, multiple education tabs / screens, and a responsive google maps screen for specific locations that are best for taking photos.",
    image: "imagynateScreenShot",
    techUsed: [
      "React Native",
      "Style Components",
      "Google Maps",
      "TypeScripte",
      "JavaScript",
    ],
    id: 2,
  },
  {
    title: "Hangman Game",
    description:
      "One the first games I code was the popular word-guessing game Hangman, coded using React.js and Typescript. Hangman is a popular word-guessing game in which players try to guess a hidden word by suggesting letters. This game was created using React.js for the frontend and Typescript for the type checking. By using React.js and Typescript together, we were able to create a fast, responsive, and well-structured game that offered players a fun and engaging experience.",
    image: "hangmanScreenShot",
    techUsed: ["React.js", "TypeScript", "HTML", "CSS", "JavaScript"],
    id: 3,
  },
  {
    title: "Tic-tac-toe",
    description:
      "This project was passed on the popular game of tic-tac-toe. Tic-tac-toe is a simple two-player game in which players take turns marking squares on a 3x3 grid. This game was created using React.js for the frontend and offered players a fun and engaging experience. This project was built to be a fast and responsive game that allowed players to move quickly and easily. The game was structured using the modular components of React.js, which made it easy to add new features and update existing ones.",
    image: "ticTacToeScreenShot",
    techUsed: ["React.js", "HTML", "CSS", "JavaScript"],
    id: 4,
  },
];
