import "../styles/main-app.css";

export function HangmanWord({ guessedLetters, wordToGuess, reveal = false }) {
  return (
    <div className="hangman-word-text">
      {wordToGuess.split("").map((letter, index) => (
        <span className="hangman-word" key={index}>
          <span
            style={{
              visibility:
                guessedLetters.includes(letter) || reveal
                  ? "visible"
                  : "hidden",
              color: !guessedLetters.includes(letter) && reveal ? "yellow" : "",
            }}
          >
            {letter}
          </span>
        </span>
      ))}
    </div>
  );
}
