import styles from "../styles/keyboard-module.css";
const KEYS = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export function KeyBoard({
  activeLetters,
  inactiveLetters,
  addGuessedLetter,
  disabled = false,
}) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        width: "100%",
        marginTop: "3rem",
        marginBottom: "3rem",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(75px, 1fr))",
          gap: ".5rem",
        }}
      >
        {KEYS.map((key) => {
          const isActive = activeLetters.includes(key);
          const isInactive = inactiveLetters.includes(key);
          return (
            <button
              onClick={() => addGuessedLetter(key)}
              className={`btn ${isActive ? "active" : ""} ${
                isInactive ? "inactive" : ""
              }`}
              key={key}
              disabled={isInactive || isActive || disabled}
            >
              {key}
            </button>
          );
        })}
      </div>
    </div>
  );
}
