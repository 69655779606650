import React from "react";
import "../styling/projects.scss";
import { projects } from "../assets/projects";
import screenShot from "../assets/img/projectImages/rideFileScreenShot.png";
import unDraw1 from "../assets/svg/FeelingProud.svg";
import unDraw2 from "../assets/svg/code_inspection.svg";
import unDraw3 from "../assets/svg/code_snippets.svg";
import unDraw4 from "../assets/svg/hacker_mind.svg";
import unDraw5 from "../assets/svg/mobile_development.svg";
import unDraw6 from "../assets/svg/pair_programming.svg";
import unDraw7 from "../assets/svg/programming.svg";
import unDraw8 from "../assets/svg/static_website.svg";

export const Projects = () => {
  const randomSvg = [
    unDraw1,
    unDraw2,
    unDraw3,
    unDraw5,
    unDraw6,
    unDraw7,
    unDraw8,
  ];

  return (
    <div className="main-projects-container">
      {projects.map((data) => {
        return (
          <div className="main-single-project-card-container">
            {/* <img
              className="OnDeskCoderSVG"
              src={randomSvg[Math.floor(Math.random() * randomSvg.length)]}
              alt=""
            /> */}
            <div className="single-project-image-container">
              <img
                src={require("../assets/img/projectImages/" +
                  data.image +
                  ".png")}
                alt="Project Image"
              />
              <div
                className="tech-main-container"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3>Tech Stack</h3>
                <div className="tech-container">
                  {data.techUsed.map((data2) => {
                    return <div className="tech-bubble">{data2}</div>;
                  })}
                </div>
              </div>
            </div>
            <div className="single-project-text-container">
              <h3 className="single-project-title">{data.title}</h3>
              <div className="project-underline"></div>
              <p className="single-project-text-para">{data.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
