import React from "react";
import "../styling/progressBar.scss";

export const ProgressBar = ({ amount }) => {
  let amountOfDivs = amount;

  return (
    <div class="progress">
      <div class="track">
        {[...Array(amountOfDivs)].map((e, i) => (
          <div class="bar" key={i}></div>
        ))}
      </div>
    </div>
  );
};
