import React from "react";
import "../styles/RestButton.css";

export const RestButton = ({ resetBoard }) => {
  return (
    <button className="reset-button" onClick={resetBoard}>
      Reset
    </button>
  );
};

export default RestButton;
