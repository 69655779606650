import "../App.css";
import { Hero } from ".//Hero";
import { ResumeEmail } from ".//ResumeEmail";
import { SkillsSection } from "./Skills";

function MainPage({ setShowNav }) {
  return (
    <div className="App">
      <Hero />
      <SkillsSection setShowNav={setShowNav} />
      <ResumeEmail setShowNav={setShowNav} />
    </div>
  );
}

export default MainPage;
