import React, { useState } from "react";
import "../styling/resumeEmail.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import linkedInLogo from "../assets/svg/linkedin.svg";
import githubLogo from "../assets/svg/github.svg";
import instagramLogo from "../assets/svg/instagram.svg";
import { EmailModal } from "./emailModal";

export const ResumeEmail = ({ setShowNav }) => {
  const [openModal, setOpenModal] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="connect-container" id="resumeEmail">
      <div data-aos="fade-up" className="connect-header">
        <h2>Lets Connect.</h2>
      </div>
      <div className="connect-socials-card">
        <h3>Socials</h3>
        <div className="socials-img-container">
          <a href="https://www.linkedin.com/in/arthur-silvadev/">
            <img className="social-img" src={linkedInLogo} alt="" />
          </a>
          <a href="https://github.com/Arthur1112">
            <img className="social-img" src={githubLogo} alt="" />
          </a>
          <a href="https://www.instagram.com/arthursilva_18/">
            <img className="social-img" src={instagramLogo} alt="" />
          </a>
        </div>
      </div>
      <div className="connect-email-card">
        <h3>Prefer to email?</h3>
        <div class="buttons">
          <button
            onClick={() => {
              setShowNav(false);
              setOpenModal(true);
              document.body.style.overflow = "hidden";
            }}
            className={"email-btn2"}
          >
            Email
          </button>
        </div>
      </div>
      <EmailModal
        open={openModal}
        emailSuccess={emailSuccess}
        setEmailSuccess={setEmailSuccess}
        onClose={() => {
          setShowNav(true);
          setOpenModal(false);
          setEmailSuccess(false);
          document.body.style.overflow = "unset";
        }}
      />
      <div className="exampled">
        <div class="shadow">
          <div class="triangle-wrapper">
            <div class="triangle"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
