import React from "react";
import "../styling/NavBar2.scss";
import { HashLink as Link } from "react-router-hash-link";

export const NavBar2 = ({ showNav }) => {
  if (showNav === false) return null;
  return (
    <div className="nav-body">
      <div class="nav">
        <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <div class="menu">
          <Link to="#home" smooth className="navLinks">
            <li>
              <a>home</a>
            </li>
          </Link>
          <Link to="#aboutMe" smooth className="navLinks">
            <li>
              <a>about</a>
            </li>
          </Link>
          <Link to="#resumeEmail" smooth className="navLinks">
            <li>
              <a>contact</a>
            </li>
          </Link>
        </div>
      </div>
    </div>
  );
};
