import React from "react";
import "../styling/hero.scss";
import HeroImage from "../assets/img/HeroImageCopy.png";
import triangle from "../assets/svg/triangle.svg";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { LightSabers } from "./LightSabers";

export const Hero = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="svg-container">
        <svg
          className="hero-svg"
          width="380px"
          height="500px"
          viewBox="0 0 837 1045"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlnsSketch="http://www.bohemiancoding.com/sketch/ns"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            sketchType="MSPage"
          >
            <path
              d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
              id="Polygon-1"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>

            <path
              d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
              id="Polygon-2"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>
            <path
              d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
              id="Polygon-3"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>
            <path
              d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
              id="Polygon-4"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>
            <path
              d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
              id="Polygon-5"
              stroke-width="6"
              sketchType="MSShapeGroup"
            ></path>
          </g>
        </svg>
      </div>
      <div className="HeroContainer" id="home">
        <div className="HeroInnerContainer">
          <div className="light-saber-container"></div>
          <div className="css-typing">
            <div className="glitch-wrapper">
              <h1 data-glitch="Arthur Silva" className="glitch hero-text">
                <strong className="different-color-text">Arthur Silva</strong>
              </h1>
            </div>
            <div class="wrapper">
              <h3 className="hero-text typing-demo">
                <strong className="different-color-text">&#x3c;h3&#x3e;</strong>{" "}
                Front-End Developer.{" "}
                <strong className="different-color-text">
                  &#x3c;/h3&#x3e;
                </strong>{" "}
              </h3>
            </div>
          </div>
          {/* <Link smooth to="#aboutMe">
            <button className="hero-button">Find out more about me...</button>
          </Link> */}
          <LightSabers />
        </div>
      </div>
      <div className="svg-wrapper">
        <div class="custom-shape-divider-bottom">
          <svg
            data-name="Layer 1"
            className="waves parallax"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
            id="wave1"
          >
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
          <svg
            data-name="Layer 1"
            className="waves parallax"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
            id="wave2"
          >
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
          </svg>
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
            className="waves parallax"
            id="wave3"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};
