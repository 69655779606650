import "./App.css";
import { NavBar } from "./components/NavBar";
import { BrowserRouter, Routes } from "react-router-dom";
import { useState } from "react";
import MainPage from "./components/MainPage";
import Hangman from "./components/games/hangman/src/hangman-app";
import TicTacToe from "./components/games/ticTacToe/src/ticTacToeApp";
import MatchingGame from "./components/games/matchingGame/src/MatchingApp";
import { Route } from "react-router-dom";
import { NavBar2 } from "./components/NavBar2";

function App() {
  const [showNav, setShowNav] = useState(true);
  const [theme, setTheme] = useState();

  return (
    <BrowserRouter>
      <div data-theme={theme} className="App">
        <NavBar2 showNav={showNav} />
        <div className="theme-container">
          <div className="themeSwitcher">
            <input className="theme-button" type="checkbox" />
            <div className="inner-theme-contaier">
              <button
                className="themeButton"
                id="cyan"
                onClick={() => {
                  setTheme();
                }}
              ></button>
              <button
                className="themeButton"
                id="orange"
                onClick={() => {
                  setTheme("orange");
                }}
              ></button>
              <button
                className="themeButton"
                id="green"
                onClick={() => {
                  setTheme("green");
                }}
              ></button>
              <button
                className="themeButton"
                id="purple"
                onClick={() => {
                  setTheme("purple");
                }}
              ></button>
              <button
                className="themeButton"
                id="sith"
                onClick={() => {
                  setTheme("sith");
                }}
              >
                Sith
              </button>
              <button
                className="themeButton"
                id="jedi"
                onClick={() => {
                  setTheme("jedi");
                }}
              >
                Jedi
              </button>
            </div>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<MainPage setShowNav={setShowNav} />} />
          <Route
            path="/hangman"
            element={<Hangman setShowNav={setShowNav} />}
          />
          <Route
            path="/tic-tac-toe"
            element={<TicTacToe setShowNav={setShowNav} />}
          />
          <Route
            path="/matching-game"
            element={<MatchingGame setShowNav={setShowNav} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
